import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMaterialsList() {
  // Use toast
  const toast = useToast()

  const refMaterialListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'category_type' },
    { key: 'description' },
    { key: 'id', sortable: true, label: 'Created at' },
    { key: 'actions' },
  ]
  const selectMode = 'single'
  const perPage = ref(10)
  const totalMaterials = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoryTypeFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refMaterialListTable.value ? refMaterialListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMaterials.value,
    }
  })

  const refetchData = () => {
    refMaterialListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, categoryTypeFilter], () => {
    refetchData()
  })

  const fetchStyleMaterials = (ctx, callback) => {
    const ordering = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
    store
      .dispatch('app-style/fetchStyleMaterials', {
        id: router.currentRoute.params.id,
        ordering,
        /*
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category_type: categoryTypeFilter.value,
        */
      })
      .then(response => {
        const materials = response.data.style_material
        callback(materials)
        totalMaterials.value = materials.length
      })
      .catch(error => {
        if (error.response.status === 404) {
          callback([])
        }
      })
  }

  const fetchMaterials = (ctx, callback) => {
    store
      .dispatch('app-style/fetchMaterials', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category_type: categoryTypeFilter.value,
      })
      .then(response => {
        const materials = response.data.results
        callback(materials)
        totalMaterials.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materials list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchMaterials,
    fetchStyleMaterials,
    tableColumns,
    selectMode,
    perPage,
    currentPage,
    totalMaterials,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaterialListTable,
    refetchData,

    categoryTypeFilter,
  }
}
