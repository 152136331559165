import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

export default function useMaterialsList() {
  const refEmbellishmentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'category_type' },
    { key: 'description' },
    { key: 'id', sortable: true, label: 'Created at' },
    { key: 'actions' },
  ]
  const selectMode = 'single'
  const perPage = ref(10)
  const totalEmbellishments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoryTypeFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refEmbellishmentListTable.value ? refEmbellishmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmbellishments.value,
    }
  })

  const refetchData = () => {
    refEmbellishmentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, categoryTypeFilter], () => {
    refetchData()
  })

  const fetchStyleEmbellishments = (ctx, callback) => {
    const ordering = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
    store
      .dispatch('app-style/fetchStyleEmbellishments', {
        id: router.currentRoute.params.id,
        ordering,
        /*
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category_type: categoryTypeFilter.value,
        */
      })
      .then(response => {
        const embellishments = response.data.style_embellishment
        callback(embellishments)
        totalEmbellishments.value = embellishments.length
      })
      .catch(error => {
        if (error.response.status === 404) {
          callback([])
        }
      })
  }

  return {
    fetchStyleEmbellishments,
    tableColumns,
    selectMode,
    perPage,
    currentPage,
    totalEmbellishments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEmbellishmentListTable,
    refetchData,

    categoryTypeFilter,
  }
}
